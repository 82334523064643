<template>
    <div>
        <load-spinner v-if="isLoadingPayment"></load-spinner>
        <base-header :base-title="headTitle"></base-header>
        <div
            class="kt-content kt-grid__item kt-grid__item--fluid"
            id="kt_content"
        >
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitleName"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <router-link
                                class="btn btn-default btn-bold btn-upper btn-font-sm mr-2"
                                :to="{
                                    name: 'manajement-payment-id',
                                    params: { id: idRoute }
                                }"
                            >
                                <i class="flaticon2-back"></i>
                                {{ $t("button.previous") }}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body" v-if="!isLoadingPayment">
                    <!-- content -->
                    <div class="row">
                        <div class="col-md-6">

                            <div class="form-group row mb-2">
                                <label
                                    class="col-3 col-form-label font-weight-bold"
                                    >Order ID</label
                                >
                                <label class="col-7 col-form-label">{{
                                    detailPayment.transactionOrderId
                                }}</label>
                            </div>
                            <div class="form-group row mb-2">
                                <label
                                    class="col-3 col-form-label font-weight-bold"
                                    >Status </label
                                >
                                <label class="col-7 col-form-label">{{
                                    detailPayment.paymentTransactionStatus
                                }}</label>
                            </div>
                            <div class="form-group row mb-2">
                                <label
                                    class="col-3 col-form-label font-weight-bold"
                                    >Created Date </label
                                >
                                <label class="col-7 col-form-label">{{
                                    detailPayment.createdDate | formatDate
                                }}</label>
                            </div>
                            <div class="form-group row mb-2">
                                <label
                                    class="col-3 col-form-label font-weight-bold"
                                    >Expired Date </label
                                >
                                <label class="col-7 col-form-label">{{
                                    detailPayment.expiredAt | formatDate
                                }}</label>
                            </div>
                            <div class="form-group row mb-2">
                                <label
                                    class="col-3 col-form-label font-weight-bold"
                                    >Total </label
                                >
                                <label class="col-7 col-form-label">{{
                                    detailPayment.grossAmount | currencyRupiah
                                }}</label>
                            </div>
                            <div class="form-group row mb-2 card p-2" v-if="detailPayment.payments">
                              <h4 class="pl-2">History Payment</h4>
                              <!-- <table class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>Created</th>
                                    <th>Payment</th>
                                    <th>Status</th>
                                    <th>Expired At</th>
                                  </tr>
                                </thead>
                                <tbody v-if="detailPayment.payment">
                                  <tr>
                                    <td>
                                      {{detailPayment.payment.transactionTime | formatDate}}
                                    </td>
                                    <td>
                                      {{detailPayment.payment.paymentType}}
                                    </td>
                                    <td>
                                      {{detailPayment.payment.transactionStatus}}
                                    </td>
                                    <td>
                                      {{detailPayment.payment.timeExpired | formatDate}}
                                    </td>
                                  </tr>
                                </tbody>

                              </table> -->
                              <history-transaksi-payment :payments="detailPayment.payments" />
                            </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row mb-2">
                                <label
                                    class="col-4 col-form-label font-weight-bold"
                                    >Link Payment</label
                                >
                                <div class="col-8 col-form-label ">
                                 <a class="text-wrap" style="word-break: break-all" :href=" changeURLSNAP(detailPayment.transactionOrderId)" target="_blank">
                                   {{
                                    changeURLSNAP(detailPayment.transactionOrderId)
                                }}</a>
                                </div>
                            </div>
                          <template v-if="detailPayment.customerDetail">
                              <div class="form-group row mb-2">
                                <label
                                    class="col-4 col-form-label font-weight-bold"
                                    >Name Customer </label
                                >
                                <label class="col-7 col-form-label">{{
                                    detailPayment.customerDetail.first_name
                                }} {{
                                    detailPayment.customerDetail.last_name
                                }}</label>
                            </div>
                            </template>
                            <template v-if="detailPayment.customerDetail">
                            <div class="form-group row mb-2">
                                <label
                                    class="col-4 col-form-label font-weight-bold"
                                    >Phone Customer </label
                                >
                                <label class="col-7 col-form-label">{{
                                    detailPayment.customerDetail.phone
                                }} </label>
                            </div>
                            </template>
                            <template v-if="detailPayment.customerDetail">
                            <div class="form-group row mb-2">
                                <label
                                    class="col-4 col-form-label font-weight-bold"
                                    >Email Customer </label
                                >
                                <label class="col-7 col-form-label">{{
                                    detailPayment.customerDetail.email
                                }} </label>
                            </div>
                          </template>
                          <template v-if="detailPayment.itemDetail">
                            <div class="form-group row mb-2 card p-2">
                               <h4 class="pl-2">Item Detail</h4>
                               <div class="col-12">
                                 <table class="table table-bordered">
                                   <thead>
                                     <tr>
                                       <th>Name</th>
                                       <th class="text-center">Qty</th>
                                       <th>Price</th>
                                     </tr>
                                   </thead>
                                   <tbody>
                                     <tr v-for="item, index in detailPayment.itemDetail" :key="index">
                                       <td>{{item.name}}</td>
                                       <td class="text-center">{{item.quantity}}</td>
                                       <td>{{item.price | currencyRupiah}}</td>
                                     </tr>
                                   </tbody>
                                 </table>
                               </div>
                            </div>
                          </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTitle from "./../../components/_base/BaseTitle";
import BaseHeader from "./../../components/_base/BaseHeader";
import LoadSpinner from "./../../components/_general/LoadSpinner";
import { mapState, mapActions } from "vuex";
import HistoryTransaksiPayment from "./HistoryTransaksiPayment.vue"
export default {
    components: {
        BaseTitle,
        BaseHeader,
        LoadSpinner,
        HistoryTransaksiPayment
    },
    data() {
        return {
            headTitleName: "Detail Transaksi",
            headTitle: "Detail Payment",
            idRoute: ""
        };
    },
    computed: {
        ...mapState("PaymentStore", {
            isLoadingPayment: state => state.isLoadingPayment,
            detailPayment: state => state.detailPayment
        })
    },
    async mounted() {
        this.idRoute =
            this.$route.params && this.$route.params.id
                ? this.$route.params.id
                : "";
        this.headTitleName += ` ${this.$route.params.payment}`;
        this.getDetailPayment(this.$route.params.payment);
    },

    methods: {
        ...mapActions({
            getDetailPayment: "PaymentStore/GET_PAYMENT_DETAIL"
        }),
        changeURLSNAP(orderId){
          if(orderId) return `${process.env.VUE_APP_SNAP_PAYMENT}${orderId}`
          return ''
      }
    },
    filters: {
      formatDate(date) {
        if(!date) return ''
        return moment(date).format("dddd, DD MMMM YYYY , HH:mm");
      },
      currencyRupiah(curr) {
         if(!curr) return ''
        const format = curr.toString().split('').reverse().join('');
        const convert = format.match(/\d{1,3}/g);
        const rupiah = convert.join('.').split('').reverse().join('')
        return `Rp ${rupiah}`
      },
      
    }
};
</script>

<style></style>
